import {
  Badge,
  Box,
  Button,
  Icon,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEvent, useGate, useStore } from "effector-react";
import { option as O } from "fp-ts/";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { Virtuoso } from "react-virtuoso";
import { frmt } from "../format";
import { stores } from "../stores";
import { BiReset } from "react-icons/bi";
import { backend } from "./backend-store";

export const OrdersPending: React.FC = () => {
  useGate(backend.PendingOrdersGate);
  const contract = useStore(stores.$contract);
  const orders = useStore(stores.$ordersPending);
  const onSelect = useEvent(backend.onOrderSelect);
  const onCancel = useEvent(backend.onOrderCancel);

  return pipe(
    contract,
    O.map(() =>
      pipe(
        O.fromPredicate(() => orders.length > 0)(orders),
        O.map(() => (
          <>
            <Virtuoso
              style={{ height: "500px" }}
              data={orders}
              itemContent={(_, order) => (
                <Stack
                  direction="row"
                  align="center"
                  className="list-item"
                  borderY="1px solid gray"
                  marginTop="-1px"
                >
                  <Box
                    cursor="pointer"
                    onClick={() => onSelect(O.some(order))}
                    paddingY={4}
                    sx={{
                      ".list-item:hover &": {
                        backgroundColor: "gray.600",
                      },
                    }}
                  >
                    <Stat>
                      <StatLabel>
                        <Tag>{order.batch_id}</Tag>{" "}
                        <Tag
                          as="a"
                          href={frmt.explorerAddr(
                            order.chain_id,
                            order.receiver
                          )}
                          target="_blank"
                        >
                          {order.receiver}
                        </Tag>
                      </StatLabel>
                      <StatNumber>
                        {frmt.units(order.amount)} <Badge>MNDCC</Badge>{" "}
                      </StatNumber>

                      <StatHelpText>
                        {order.vesting_start_date
                          ? frmt.date(order.vesting_start_date)
                          : null}{" "}
                        <Box maxWidth="sm">
                          <Tag
                            as="a"
                            size="sm"
                            color="orange.300"
                            href={frmt.explorer(order.chain_id, order.txid)}
                            target="_blank"
                          >
                            <Text isTruncated>{order.txid}</Text>
                          </Tag>
                        </Box>
                      </StatHelpText>
                    </Stat>
                  </Box>
                  <Box
                    cursor="pointer"
                    onClick={() => onCancel({ _id: order._id })}
                    marginX="2"
                    height="100%"
                  >
                    <Button
                      size="sm"
                      leftIcon={<Icon as={BiReset} boxSize="5" />}
                    >
                      {frmt.age(order.tx_timestamp)}
                    </Button>
                  </Box>
                </Stack>
              )}
            />
            <Stack
              spacing={4}
              align="start"
              divider={<StackDivider color="gray.200" />}
            ></Stack>
          </>
        )),
        O.getOrElse(() => <>No Pending Orders</>)
      )
    ),
    O.getOrElse(() => <>Please connect wallet</>)
  );
};
