import {
  Badge,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useGate, useStore } from "effector-react";
import { option as O } from "fp-ts/";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { Virtuoso } from "react-virtuoso";
import { frmt } from "../format";
import { stores } from "../stores";
import { backend } from "./backend-store";

export const OrdersConfirmed: React.FC = () => {
  useGate(backend.ConfirmedOrdersGate);
  const contract = useStore(stores.$contract);
  const orders = useStore(stores.$ordersConfirmed);

  return pipe(
    contract,
    O.map(() =>
      pipe(
        O.fromPredicate(() => orders.length > 0)(orders),
        O.map(() => (
          <>
            <Virtuoso
              style={{ height: "500px" }}
              data={orders}
              itemContent={(_, order) => (
                <Stack
                  className="list-item"
                  borderY="1px solid gray"
                  marginTop="-1px"
                >
                  <Stat>
                    <StatLabel>
                      <Tag>{order.batch_id}</Tag> {order.receiver}
                    </StatLabel>
                    <StatNumber>
                      {order.amount !== order.amount_confirmed && (
                        <Text as="del" color="red.200">
                          {frmt.units(order.amount)}{" "}
                        </Text>
                      )}
                      {frmt.units(order.amount_confirmed!)} <Badge>MNDCC</Badge>
                    </StatNumber>
                    <StatHelpText>
                      {order.vesting_start_date
                        ? frmt.date(order.vesting_start_date)
                        : null}{" "}
                      {order.txid !== order.txid_confirmed && (
                        <Tag size="sm" color="red.200">
                          <Text as="del">{order.txid}</Text>
                        </Tag>
                      )}
                      <Tag
                        as="a"
                        size="sm"
                        color="green.200"
                        href={frmt.explorer(order.chain_id, order.txid)}
                        target="_blank"
                      >
                        {order.txid_confirmed}
                      </Tag>
                    </StatHelpText>
                  </Stat>
                </Stack>
              )}
            />
            <Stack
              spacing={4}
              align="start"
              divider={<StackDivider color="gray.200" />}
            ></Stack>
          </>
        )),
        O.getOrElse(() => <>No Confirmed Orders</>)
      )
    ),
    O.getOrElse(() => <>Please connect wallet</>)
  );
};
