import { Box, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { useStore } from "effector-react";
import { option as O } from "fp-ts";
import * as React from "react";
import DatePicker from "../date-picker";
import { AmountInput } from "../widgets/amount";
import { Buttons } from "../widgets/buttons";
import { RecipientInput } from "../widgets/recipient";
import { blockchainStore } from "./blockchain-store";

export const VestedTransfer = () => {
  const amount = useStore(blockchainStore.$amount);
  const startDate = useStore(blockchainStore.$startDate);
  const recipient = useStore(blockchainStore.$recipient);
  const locked = useStore(blockchainStore.$locked);
  return (
    <>
      <Stack spacing={4}>
        <RecipientInput
          disabled={locked}
          recipient={recipient}
          onChange={blockchainStore.onRecipientChange}
        />

        <Stack spacing={10}>
          <Stack
            direction={{ base: "column", sm: "row" }}
            align={"start"}
            justify={"space-between"}
          >
            <Box>
              <FormControl id="vesting-start">
                <FormLabel>Vesting Start Date</FormLabel>
                <DatePicker
                  disabled={locked}
                  onChange={(date: any) =>
                    blockchainStore.onStartDateChange(date)
                  }
                  selectedDate={O.getOrElse(() => new Date())(startDate)}
                />
              </FormControl>
            </Box>
            <AmountInput
              disabled={locked}
              amount={amount}
              onChange={blockchainStore.onAmountChange}
            />
          </Stack>
          <Buttons.Primary onClick={() => blockchainStore.onTransferVested()}>
            Transfer Vested
          </Buttons.Primary>
        </Stack>
      </Stack>
    </>
  );
};
