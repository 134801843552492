import { LockIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  HStack,
  Icon,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tag,
} from "@chakra-ui/react";
import { useEvent, useGate, useStore } from "effector-react";
import { option as O } from "fp-ts/";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { IoMdTrash } from "react-icons/io";
import { Virtuoso } from "react-virtuoso";
import { frmt } from "../format";
import { stores } from "../stores";
import { backend } from "./backend-store";

export const Orders: React.FC = () => {
  useGate(backend.OrdersGate);
  const contract = useStore(stores.$contract);
  const orders = useStore(stores.$orders);
  const onSelect = useEvent(backend.onOrderSelect);
  const onDelete = useEvent(backend.onOrderDelete);

  return pipe(
    contract,
    O.map(() =>
      pipe(
        O.fromPredicate(() => orders.length > 0)(orders),
        O.map(() => (
          <>
            <Virtuoso
              style={{ height: "500px" }}
              data={orders}
              itemContent={(_, order) => (
                <Box borderY="1px solid gray" marginTop="-1px">
                  <Stack direction="row" align="center" className="list-item">
                    <Box
                      cursor="pointer"
                      onClick={() => onSelect(O.some(order))}
                      paddingY={4}
                      sx={{
                        ".list-item:hover &": {
                          backgroundColor: "gray.600",
                        },
                      }}
                    >
                      <Stat>
                        <StatLabel>
                          <Tag>{order.batch_id}</Tag> {order.receiver}
                        </StatLabel>
                        <StatNumber>
                          {frmt.units(order.amount)} <Badge>MNDCC</Badge>{" "}
                        </StatNumber>
                        {order.vesting_start_date ? (
                          <StatHelpText>
                            {frmt.date(order.vesting_start_date)}
                          </StatHelpText>
                        ) : null}
                      </Stat>
                    </Box>
                    <Box
                      cursor="pointer"
                      onClick={() => onDelete({ _id: order._id })}
                      marginX="2"
                      height="100%"
                    >
                      <Icon as={IoMdTrash} boxSize="7" />
                    </Box>
                  </Stack>
                  {!!order.lock && (
                    <Box>
                      <Tag bg="transparent" borderWidth={1} size="sm">
                        <HStack
                          spacing={2}
                          divider={<StackDivider borderColor="gray.600" />}
                        >
                          <Box>
                            <LockIcon />
                          </Box>
                          <Box>{order.lock}</Box>
                          <Box>{frmt.timestamp(order.lock_timestamp)}</Box>
                        </HStack>
                      </Tag>
                    </Box>
                  )}
                </Box>
              )}
            />
            <Stack
              spacing={4}
              align="start"
              divider={<StackDivider color="gray.200" />}
            ></Stack>
          </>
        )),
        O.getOrElse(() => <>No Open Orders</>)
      )
    ),
    O.getOrElse(() => <>Please connect wallet</>)
  );
};
