import { attachmentEvents } from "../events/attachment";
import { genId } from "../idgen";
import { AttachmentFile } from "../types";

export const upload = {
  onUploadAdded: (file: File) =>
    attachmentEvents.attachmentAddedEvent(createAttachment(file)),
};

function createAttachment(file: File): AttachmentFile {
  return {
    _id: genId(),
    attachedTo: genId(),
    creationTime: new Date(),
    mimeType: file.type,
    originalName: file.name,
    tags: [],
    data: file,
  };
}
