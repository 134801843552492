import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEvent, useStore } from "effector-react";
import { option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoMdPower } from "react-icons/io";
import { stores } from "../stores";
import { Buttons } from "../widgets/buttons";
import { backend } from "./backend-store";
import { Logout } from "./logout";
import { Orders } from "./orders";
import { OrdersConfirmed } from "./orders-confirmed";
import { OrdersPending } from "./orders-pending";
import { Upload } from "./upload";

export const Backend = () => {
  const token = useStore(stores.$token);
  const userName = useStore(backend.$userName);
  const password = useStore(backend.$password);
  const tabIndex = useStore(backend.$tabIndex);
  const onUserNameChange = useEvent(backend.onUserNameChange);
  const onPasswordChange = useEvent(backend.onPasswordChange);
  const onLogin = useEvent(backend.onLogin);
  const onTabChange = useEvent(backend.onTabChange);

  return pipe(
    token,
    O.map(() => (
      <>
        <Stack w="2xl">
          <Box rounded={"lg"} bg={"gray.700"} boxShadow={"lg"} p={8}>
            <Tabs index={tabIndex} onChange={onTabChange} isLazy>
              <TabList>
                <Tab>Open</Tab>
                <Tab>Pending</Tab>
                <Tab>Confirmed</Tab>
                <Tab>
                  <Icon as={AiOutlineCloudUpload} boxSize="6" />
                </Tab>
                <Tab>
                  <Icon as={IoMdPower} boxSize="6" />
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Orders />
                </TabPanel>
                <TabPanel>
                  <OrdersPending />
                </TabPanel>
                <TabPanel>
                  <OrdersConfirmed />
                </TabPanel>
                <TabPanel>
                  <Upload />
                </TabPanel>
                <TabPanel>
                  <Logout />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </>
    )),
    O.getOrElse(() => (
      <>
        <Stack spacing={4}>
          <FormControl id="username">
            <FormLabel>User Name</FormLabel>
            <Input
              value={O.getOrElseW(() => "")(userName)}
              onChange={(e) => onUserNameChange(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={O.getOrElseW(() => "")(password)}
              onChange={(e) => onPasswordChange(e.currentTarget.value)}
            />
          </FormControl>
          <Buttons.Primary onClick={() => onLogin()}>Login</Buttons.Primary>
        </Stack>
      </>
    ))
  );
};
