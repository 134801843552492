import { useEvent } from "effector-react";
import * as React from "react";
import { events } from "../events";

export const Logout: React.FC = () => {
  const onLogout = useEvent(events.logoutEvent);
  React.useEffect(() => {
    onLogout();
  });
  return null;
};
