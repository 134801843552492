import { Flex, Heading, Stack, StackDivider } from "@chakra-ui/react";
import * as React from "react";
import { Backend } from "./backend";
import { Blockchain } from "./blockchain";

export const ControlCenter = () => {
  return (
    <Flex minH={"100vh"} justify={"center"} bg={"gray.800"}>
      <Stack spacing={8} mx={"auto"} maxW={"5xl"} py={12} px={6}>
        <Stack align={"center"} spacing={10}>
          <Heading fontSize={"4xl"}>MNDCC Control Center</Heading>
          <Stack
            direction={["column", "row"]}
            spacing={10}
            divider={<StackDivider color="gray.200" />}
            align="start"
          >
            <Stack align={"center"}>
              <Heading fontSize="2xl">Backend</Heading>
              <Backend />
            </Stack>
            <Stack align={"center"}>
              <Heading fontSize="2xl">Blockchain</Heading>
              <Blockchain />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
