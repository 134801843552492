import { combine, Event, merge, sample } from "effector";
import { createGate } from "effector-react";
import { option as O } from "fp-ts/";
import { pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import domain from "../domain";
import { effects } from "../effects";
import { events } from "../events";
import { stores } from "../stores";

const OrdersGate = createGate();
const PendingOrdersGate = createGate();
const ConfirmedOrdersGate = createGate();

function createChainAndContractEvent(clock: Event<any>) {
  return sample({
    source: combine({
      chainId: stores.$chainId,
      contract: stores.$contract,
    }),
    clock,
  })
    .filter({
      fn: ({ chainId, contract }) => O.isSome(chainId) && O.isSome(contract),
    })
    .map(({ chainId, contract }) => ({
      chainId: "" + O.getOrElseW(() => undefined)(chainId)!,
      contractAddress: pipe(
        contract,
        O.map((c) => c.address),
        O.getOrElseW(() => undefined)
      )!,
    }));
}

createChainAndContractEvent(
  merge([OrdersGate.open, stores.$contract, events.txidSetEvent])
).watch(events.ordersRequestedEvent);

createChainAndContractEvent(
  merge([
    PendingOrdersGate.open,
    stores.$contract,
    events.txidSetEvent,
    effects.backend.uploadAttachmentFx.done,
  ])
).watch(events.pendingOrdersRequestedEvent);
createChainAndContractEvent(
  merge([ConfirmedOrdersGate.open, stores.$contract])
).watch(events.confirmedOrdersRequestedEvent);

const onLogin = domain.createEvent("onLogin");
const onUserNameChange = domain.createEvent<string>("onUserNameChange");
const onPasswordChange = domain.createEvent<string>("onPasswordChange");
const onTabChange = domain.createEvent<number>("backend tab change");

const $userName = domain
  .createStore<Option<string>>(O.none, {
    name: "userName",
  })
  .on(onUserNameChange, (_, payload) => O.fromNullable(payload))
  .reset(events.logoutEvent);

const $password = domain
  .createStore<Option<string>>(O.none, {
    name: "password",
  })
  .on(onPasswordChange, (_, payload) => O.fromNullable(payload))
  .reset(events.logoutEvent);

const $loginRequest = combine({
  userName: $userName,
  password: $password,
});

const $tabIndex = domain
  .createStore<number>(0, {
    name: "blockchain tab index",
  })
  .on(onTabChange, (_, payload) => payload)
  .reset(effects.backend.uploadAttachmentFx.done);

sample({
  source: $loginRequest,
  clock: onLogin,
})
  .filter({ fn: ({ userName }) => O.isSome(userName) })
  .map((payload) => ({ ...payload }))
  .watch(({ userName, password }) =>
    effects.backend.loginFx({
      userName: O.getOrElseW(() => undefined)(userName)!,
      password: O.getOrElseW(() => undefined)(password)!,
    })
  );

export const backend = {
  OrdersGate,
  PendingOrdersGate,
  ConfirmedOrdersGate,
  onOrderSelect: events.orderSelectedEvent,
  onOrderDelete: events.orderDeletionRequestedEvent,
  onOrderCancel: events.orderCancellationRequestedEvent,
  $userName,
  $password,
  $tabIndex,
  onLogin,
  onUserNameChange,
  onPasswordChange,
  onTabChange,
};
