import { Box } from "@chakra-ui/react";
import * as React from "react";
import FileDrop from "../widgets/file";
import { upload } from "./upload-store";

export const Upload = () => {
  const onAcceptFile = upload.onUploadAdded;
  return (
    <Box
      borderWidth="6px"
      borderRadius="lg"
      borderStyle="dashed"
      overflow="hidden"
      p={3}
    >
      <FileDrop label="xlsx" onAcceptFile={onAcceptFile} />
    </Box>
  );
};
