import {
  Badge,
  Box,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEvent, useStore } from "effector-react";
import { option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { frmt } from "../format";
import { stores } from "../stores";
import { Buttons } from "../widgets/buttons";
import { blockchainStore } from "./blockchain-store";
import { Locked } from "./locked";
import { Ownership } from "./ownership";
import { Transfer } from "./transfer";
import { VestedTransfer } from "./vested-transfer";

export const Blockchain = () => {
  const balance = useStore(stores.$balance);
  const contract = useStore(stores.$contract);
  const chainId = useStore(stores.$chainId);
  const tabIndex = useStore(blockchainStore.$tabIndex);
  const onTabChange = useEvent(blockchainStore.onTabChange);

  return pipe(
    contract,
    O.map(() => (
      <>
        <Flex direction="row">
          <Text color="gray.400">Network:</Text>
          <Text color="whiteAlpha.900" marginX="2">
            {pipe(
              chainId,
              O.map((cid) =>
                cid === 1 ? (
                  <Badge backgroundColor="green.500">Mainnet</Badge>
                ) : cid === 3 ? (
                  <Badge backgroundColor="orange.500">Ropsten</Badge>
                ) : cid === 137 ? (
                  <Badge backgroundColor="purple.500">Polygon</Badge>
                ) : cid === 80001 ? (
                  <Badge backgroundColor="orange.500">Mumbai</Badge>
                ) : (
                  "-"
                )
              ),
              O.getOrElseW(() => null)
            )}
          </Text>
        </Flex>
        <Flex direction="row">
          <Text color="gray.400">Contract Address:</Text>
          <Box marginX="2">
            {pipe(
              contract,
              O.map((contract) => contract.address),
              O.getOrElseW(() => "-")
            )}
          </Box>
        </Flex>
        <Flex direction="row">
          <Text color="gray.400">Your balance:</Text>
          <Text color="whiteAlpha.900" marginX="2">
            {pipe(
              balance,
              O.map(frmt.units),
              O.getOrElseW(() => "-")
            )}
          </Text>
          <Text color="gray.400">MNDCC</Text>
        </Flex>
        <Stack w="xl">
          <Box rounded={"lg"} bg={"gray.700"} boxShadow={"lg"} p={8}>
            <Tabs index={tabIndex} onChange={onTabChange}>
              <TabList>
                <Tab>Vested Transfer</Tab>
                <Tab>Transfer</Tab>
                <Tab>Ownership</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Locked />
                  <VestedTransfer />
                </TabPanel>
                <TabPanel>
                  <Locked />
                  <Transfer />
                </TabPanel>
                <TabPanel>
                  <Ownership />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </>
    )),
    O.getOrElse(() => (
      <>
        <Buttons.Primary onClick={() => blockchainStore.onConnect()}>
          Connect Wallet
        </Buttons.Primary>
      </>
    ))
  );
};
