import { forward } from "effector";
import { BigNumberish, Contract, Signer, Transaction } from "ethers";
import { option as O } from "fp-ts/";
import { Option } from "fp-ts/lib/Option";
import domain from "./domain";
import { effects } from "./effects";
import { Order } from "./types";

export interface VestedTransfer {
  contract: Contract;
  amount: string;
  recipient: string;
  startDate: Date;
}

export interface Transfer {
  contract: Contract;
  amount: string;
  recipient: string;
}

export interface OwnershipTransfer {
  contract: Contract;
  newOwner: string;
}

export interface OrderRequest {
  chainId: string;
  contractAddress: string;
}

export interface ContractConnection {
  chainId: number;
  contract: Contract;
}

export interface TxidSetPayload {
  _id: string;
  txid: string;
}

export interface DeleteOrderPayload {
  _id: string;
}

const contractConnectedEvent = domain.createEvent<ContractConnection>(
  "contract"
);
const signerRetrievedEvent = domain.createEvent<Signer>("signer");
const balanceRetrievedEvent = domain.createEvent<BigNumberish>("balance");
const reserveRetrievedEvent = domain.createEvent<BigNumberish>("reserve");

const startVestedTransferEvent = domain.createEvent<VestedTransfer>(
  "start vested transfer"
);
const vestedTransferTransmittedEvent = domain.createEvent<Transaction>(
  "vestedTransfer transmitted"
);
const startTransferEvent = domain.createEvent<Transfer>("start transfer");
const transferTransmittedEvent = domain.createEvent<Transaction>(
  "transfer transmitted"
);
const txidSetEvent = domain.createEvent<TxidSetPayload>("txid set");

const startOwnershipTransferEvent = domain.createEvent<OwnershipTransfer>(
  "start ownership transfer"
);
const connectWeb3Event = domain.createEvent("connect web3");

const orderDeletionRequestedEvent = domain.createEvent<DeleteOrderPayload>(
  "orderDeletionRequested"
);
const orderCancellationRequestedEvent = domain.createEvent<DeleteOrderPayload>(
  "orderCancellationRequested"
);
const orderDeletedEvent = domain.createEvent<DeleteOrderPayload>(
  "orderDeleted"
);
const orderCancelledEvent = domain.createEvent<DeleteOrderPayload>(
  "orderDeleted"
);
const ordersRequestedEvent = domain.createEvent<OrderRequest>(
  "ordersRequested"
);
const pendingOrdersRequestedEvent = domain.createEvent<OrderRequest>(
  "pendingOrdersRequested"
);
const confirmedOrdersRequestedEvent = domain.createEvent<OrderRequest>(
  "confirmedOrdersRequested"
);
const ordersReceivedEvent = domain.createEvent<Order[]>("ordersReceived");
const pendingOrdersReceivedEvent = domain.createEvent<Order[]>(
  "pendingOrdersReceived"
);
const confirmedOrdersReceivedEvent = domain.createEvent<Order[]>(
  "confirmedOrdersReceived"
);

const orderSelectedEvent = domain.createEvent<Option<Order>>("orderSelected");
const orderDeslectedEvent = orderSelectedEvent.filter({
  fn: (order) => O.isNone(order),
});

const permissions = [
  "tx:manage",
  "tx:exec",
  "user:manage",
  "user:passwordreset",
] as const;

type Permissions = typeof permissions;
export type Permission = Permissions[number];

export interface AuthTokenResponse {
  token: string;
}

export interface AuthToken {
  userName: string;
  permissions: Permission[];
}

export interface LoginRequest {
  userName: string;
  password: string;
}

const authTokenReceivedEvent = domain.createEvent<AuthTokenResponse>(
  "authTokenReceived"
);
const logoutEvent = domain.createEvent("logout");

forward({
  from: effects.blockchain.connectToContractFx.doneData,
  to: contractConnectedEvent,
});

forward({
  from: effects.blockchain.fetchBalanceFx.doneData,
  to: balanceRetrievedEvent,
});

forward({
  from: effects.backend.loginFx.doneData,
  to: authTokenReceivedEvent,
});

forward({
  from: effects.backend.fetchOpenOrdersFx.doneData,
  to: ordersReceivedEvent,
});

forward({
  from: effects.backend.deleteOrderFx.doneData,
  to: orderDeletedEvent,
});

forward({
  from: effects.backend.cancelOrderFx.doneData,
  to: orderCancelledEvent,
});

forward({
  from: effects.backend.fetchPendingOrdersFx.doneData,
  to: pendingOrdersReceivedEvent,
});

forward({
  from: effects.backend.fetchConfirmedOrdersFx.doneData,
  to: confirmedOrdersReceivedEvent,
});

forward({
  from: effects.blockchain.transferFx.doneData,
  to: transferTransmittedEvent,
});

forward({
  from: effects.blockchain.transferVestedFx.doneData,
  to: vestedTransferTransmittedEvent,
});

forward({
  from: effects.backend.setTxidFx.doneData,
  to: txidSetEvent,
});

export const authEvents = {
  authTokenReceivedEvent,
  logoutEvent,
};

export const events = {
  orderSelectedEvent,
  orderDeletionRequestedEvent,
  orderCancellationRequestedEvent,
  orderCancelledEvent,
  orderDeletedEvent,
  orderDeslectedEvent,
  ordersRequestedEvent,
  pendingOrdersRequestedEvent,
  confirmedOrdersRequestedEvent,
  confirmedOrdersReceivedEvent,
  pendingOrdersReceivedEvent,
  ordersReceivedEvent,
  authTokenReceivedEvent,
  logoutEvent,
  connectWeb3Event,
  contractConnectedEvent,
  startVestedTransferEvent,
  startTransferEvent,
  startOwnershipTransferEvent,
  signerRetrievedEvent,
  balanceRetrievedEvent,
  transferTransmittedEvent,
  vestedTransferTransmittedEvent,
  reserveRetrievedEvent,
  txidSetEvent,
};
