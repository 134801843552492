import { getUnixTime } from "date-fns";
import { forward, merge, sample } from "effector";
import { ethers } from "ethers";
import { option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { effects } from "./effects";
import { events } from "./events";
import { attachmentEvents } from "./events/attachment";
import { stores } from "./stores";

forward({
  from: events.startVestedTransferEvent.map(
    ({ contract, amount, recipient, startDate }) => ({
      contract,
      recipient,
      startDateUnix: getUnixTime(startDate),
      amountUnits: ethers.utils.parseUnits(amount, 18),
    })
  ),
  to: effects.blockchain.transferVestedFx,
});

forward({
  from: events.startTransferEvent.map(({ contract, amount, recipient }) => ({
    contract,
    recipient,
    amountUnits: ethers.utils.parseUnits(amount, 18),
  })),
  to: effects.blockchain.transferFx,
});

forward({
  from: events.startOwnershipTransferEvent,
  to: effects.blockchain.transferOwnershipFx,
});

forward({
  from: events.connectWeb3Event,
  to: effects.blockchain.connectToContractFx,
});

forward({
  from: events.orderDeletionRequestedEvent,
  to: effects.backend.deleteOrderFx,
});

forward({
  from: events.orderCancellationRequestedEvent,
  to: effects.backend.cancelOrderFx,
});

forward({
  from: [events.ordersRequestedEvent],
  to: effects.backend.fetchOpenOrdersFx,
});

forward({
  from: events.pendingOrdersRequestedEvent,
  to: effects.backend.fetchPendingOrdersFx,
});

forward({
  from: events.confirmedOrdersRequestedEvent,
  to: effects.backend.fetchConfirmedOrdersFx,
});

attachmentEvents.attachmentAddedEvent.watch((payload) => {
  effects.backend.uploadAttachmentFx({
    upload: {
      attachment: payload,
      data: payload.data,
      onProgress: (progressPercent: any) =>
        attachmentEvents.attachmentUploadMadeProgressEvent({
          attachmentId: payload._id,
          progressPercent,
        }),
    },
  });
});

forward({
  from: sample({
    source: stores.$orderSelected,
    clock: merge([
      events.transferTransmittedEvent,
      events.vestedTransferTransmittedEvent,
    ]).map((tx) => tx.hash),
    fn: (order, txid) => ({ order, txid }),
  })
    .filter({ fn: (tx) => O.isSome(tx.order) })
    .map(({ order, txid }) => ({
      orderId: pipe(
        order,
        O.map((o) => o._id),
        O.getOrElse(() => "0")
      ),
      txid: txid!,
    })),
  to: effects.backend.setTxidFx,
});

forward({
  from: events.contractConnectedEvent.map((payload) => payload.contract),
  to: [effects.blockchain.fetchBalanceFx],
});
